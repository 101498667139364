var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"formActivityTarget",model:{value:(_vm.formActivityTarget),callback:function ($$v) {_vm.formActivityTarget=$$v},expression:"formActivityTarget"}},[_c('v-dialog',{staticClass:"mt-5 mr-10 ml-10",attrs:{"persistent":"","width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{class:'table-activity-target'},[_c('v-card-title',{staticClass:"text-h5"},[_c('div',{staticClass:"header-target"},[_c('p',[_vm._v("Set target")]),_c('img',{attrs:{"src":require('../../assets/images/icon-close-round.svg'),"alt":"","srcset":""},on:{"click":function($event){return _vm.$emit('setLog', false)}}})])]),_c('v-card-text',[_c('div',{staticClass:"d-flex align-center mb-3"},[_c('h3',[_vm._v("Activity code:")]),_c('h3',{staticStyle:{"font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.code))])]),_c('v-tabs',{attrs:{"color":"deep-purple accent-4"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("view")]),_c('v-tab',[_vm._v("edit")]),_c('v-tab-item',[[_c('v-data-table',{staticClass:"elevation-0",attrs:{"items":_vm.target,"items-per-page":1000,"hide-default-header":"","hide-default-footer":"","fixed-header":"","max-height":"60vh"},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('thead',{staticClass:"header-report-activity"},[_c('tr',_vm._l((_vm.headers),function(header,index){return _c('th',{key:index,style:({
                          background: header.bgColor,
                          border: '1px solid #ffff',
                          color: '#ffff !important',
                        }),attrs:{"colspan":header.children.length === 0 ? 1 : header.children.length,"rowspan":header.children.length === 0 ? 2 : 1}},[_vm._v(" "+_vm._s(header.text)+" ")])}),0),_c('tr',[_vm._l((_vm.headersForTable),function(header,index){return _vm._l((header.children),function(headerChild,index2){return _c('th',{key:index + headerChild.text,style:({
                            background: headerChild.bgColor,
                            border: '1px solid #ffff',
                            color: '#ffff !important',
                          })},[_c('p',{key:index2,class:headerChild.isFixW === true ? 'w-150' : ''},[_vm._v(" "+_vm._s(headerChild.text)+" ")])])})})],2)])]}},{key:"body",fn:function(ref){return [_c('tbody',{attrs:{"set":(_vm.listTable = _vm.headersForTable.reduce(function (arr, el) {
                        el.children.length === 0 ? arr.push(el) : arr.push.apply(arr, el.children)
                        return arr
                      }, []))}},_vm._l((_vm.target),function(center){return _c('tr',{key:center.locationID},_vm._l((_vm.listTable),function(header){return _c('td',{key:header.value},[_vm._v(" "+_vm._s(header.isMoney ? _vm.numberWithCommas(center[header.value]) : center[header.value])+" ")])}),0)}),0)]}},{key:"foot",fn:function(ref){return undefined}}])})]],2),_c('v-tab-item',[[_c('v-data-table',{staticClass:"elevation-0",attrs:{"items":_vm.targetList,"hide-default-header":"","hide-default-footer":"","fixed-header":"","max-height":"60vh"},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('thead',{staticClass:"header-report-activity"},[_c('tr',_vm._l((_vm.headers),function(header,index){return _c('th',{key:index,style:({
                          background: header.bgColor,
                          border: '1px solid #ffff',
                          color: '#ffff !important',
                        }),attrs:{"colspan":header.children.length === 0 ? 1 : header.children.length,"rowspan":header.children.length === 0 ? 2 : 1}},[_vm._v(" "+_vm._s(header.text)+" ")])}),0),_c('tr',[_vm._l((_vm.headers),function(header,index){return _vm._l((header.children),function(headerChild,index2){return _c('th',{key:index + headerChild.text,style:({
                            background: headerChild.bgColor,
                            border: '1px solid #ffff',
                            color: '#ffff !important',
                          })},[_c('p',{key:index2,class:headerChild.isFixW === true ? 'w-150' : ''},[_vm._v(" "+_vm._s(headerChild.text)+" ")])])})})],2)])]}},{key:"body",fn:function(ref){return [_c('tbody',{attrs:{"set":(_vm.listTable = _vm.headers.reduce(function (arr, el) {
                        el.children.length === 0 ? arr.push(el) : arr.push.apply(arr, el.children)
                        return arr
                      }, []))}},_vm._l((_vm.targetList),function(center2,index){return _c('tr',{key:index},_vm._l((_vm.listTable),function(header,index2){return _c('td',{key:index2},[(header.value === 'location')?_c('p',[_vm._v(_vm._s(center2[header.value]))]):_c('v-text-field',{attrs:{"type":"number","rules":[function (v) { return !!v || 'This filed is required'; }]},model:{value:(center2[header.value]),callback:function ($$v) {_vm.$set(center2, header.value, $$v)},expression:"center2[header.value]"}})],1)}),0)}),0)]}},{key:"foot",fn:function(ref){return undefined}}])})]],2)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.tab == 1 ? (_vm.tab = 0) : _vm.$emit('setLog', false)}}},[_vm._v("Cancel ")]),(_vm.tab === 0)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.tab = 1}}},[_vm._v(" Edit ")]):_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.SaveTarget()}}},[_vm._v(" Save ")])],1)],1),_c('FlashMessage')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }