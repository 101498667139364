var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentRole === 4 || _vm.currentRole === 5)?_c('div',{staticClass:"mt-5 mr-10 ml-10"},[_c('div',[_vm._m(0),_c('div',{staticClass:"d-flex justify-space-between align-center mb-2",staticStyle:{"width":"100%"}},[_c('div',[_c('v-autocomplete',{class:'pb-0 pt-0 hidden-detail-err',staticStyle:{"width":"160px"},attrs:{"items":_vm.activityList,"item-text":"activityCode","item-value":"activityID","lable":"Activity","outlined":"","dense":""},model:{value:(_vm.activityID),callback:function ($$v) {_vm.activityID=$$v},expression:"activityID"}})],1),(_vm.permision.cFull || _vm.permision.cEdit)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v("Set target")]):_vm._e()],1)]),_c('v-data-table',{staticClass:"elevation-0 table-sale-byqcec",attrs:{"headers":_vm.headers,"items":[],"items-per-page":1000,"hide-default-header":"","hide-default-footer":"","fixed-header":"","max-height":"60vh"},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('thead',{staticClass:"header-report-activity"},[_c('tr',_vm._l((_vm.headers),function(header,index){return _c('th',{key:index,style:({
              background: header.bgColor,
              border: '1px solid #ffff',
              color: '#ffff !important',
            }),attrs:{"colspan":header.children.length === 0 ? 1 : header.children.length,"rowspan":header.children.length === 0 ? 2 : 1}},[_vm._v(" "+_vm._s(header.text)+" ")])}),0),_c('tr',[_vm._l((_vm.headers),function(header,index){return _vm._l((header.children),function(headerChild,index2){return _c('th',{key:index + headerChild.text,style:({
                background: headerChild.bgColor,
                border: '1px solid #ffff',
                color: '#ffff !important',
              })},[_c('p',{key:index2,class:headerChild.isFixW === true ? 'w-150' : ''},[_vm._v(" "+_vm._s(headerChild.text)+" ")])])})})],2)])]}},{key:"body",fn:function(ref){return [(_vm.isLoading)?_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":_vm.colNum}},[_c('h3',{staticClass:"text-center mt-5 mb-2"},[_vm._v("Getting your data...")]),_c('v-progress-linear',{staticClass:"mb-5",attrs:{"indeterminate":"","rounded":"","color":"primary"}})],1)])]):(_vm.activityListReport.length === 0)?_c('tbody',[_c('th',{attrs:{"colspan":_vm.colNum}},[_c('p',{staticClass:"py-2"},[_vm._v("There is no data")])])]):_c('tbody',{attrs:{"set":(_vm.listTable = _vm.headers.reduce(function (arr, el) {
            el.children.length === 0 ? arr.push(el) : arr.push.apply(arr, el.children)
            return arr
          }, []))}},[_vm._l((_vm.activityListReport),function(center){return _c('tr',{key:center.locationID},_vm._l((_vm.listTable),function(header){return _c('td',{key:header.value},[_c('p',{style:({
                textAlign: header.isMoney
                  ? 'right'
                  : header.value === 'location'
                  ? 'left'
                  : 'center',
              })},[_vm._v(" "+_vm._s(center[header.value] ? header.isPer ? center[header.value] + ' %' : header.isMoney ? _vm.numberWithCommas(center[header.value]) : center[header.value] : '-')+" ")])])}),0)}),_c('tr',{style:({ position: 'sticky', bottom: 0, background: ' #FFECBA' })},_vm._l((_vm.listTable),function(header){return _c('td',{key:header.value},[(header.value === 'location')?_c('p',[_vm._v("Total")]):_c('p',{style:({ textAlign: header.isMoney ? 'right' : 'center' })},[_vm._v(" "+_vm._s(_vm.calTotal(header))+" ")])])}),0)],2)]}},{key:"foot",fn:function(ref){return undefined}}],null,false,3683596447)}),(_vm.dialog)?_c('TargetTable',{attrs:{"dialog":_vm.dialog,"idUp":_vm.activityID,"code":_vm.activityList.find(function (e) { return e.activityID === _vm.activityID; }).activityCode,"target":_vm.activityListReport},on:{"setLog":_vm.setLog,"callData":function($event){return _vm.getReport()}}}):_vm._e()],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mb-3"},[_vm._v("Activity report")])])}]

export { render, staticRenderFns }